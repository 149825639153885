/* Agrega las animaciones CSS */
.menu-open {
    transform: rotate(90deg); /* Rota el icono de tres puntos cuando el menú se abre */
    transition: transform 0.3s ease-in-out; /* Aplica una animación de transición suave a la rotación */
  }
  
  .menu-dropdown {
    max-height: 0; /* Inicialmente oculta el menú desplegable */
    overflow: hidden;
    transition: max-height 0.3s ease-in-out; 
    margin-left: -15px;
    margin-bottom: 20px;/* Aplica una animación de transición suave a la altura máxima */
  }
  
  .menu-open + .menu-dropdown {
    max-height: 1000px; /* Muestra el menú desplegable cuando el botón de menú se abre */
  }
  