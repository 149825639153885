@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ::-webkit-scrollbar {
    width: 2px; /* Ancho de la barra vertical */
    height: 2px; /* Altura de la barra horizontal */
  }
/* scroll thumb and track */
}
html{
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Estilo de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 2px; /* Ancho de la barra de desplazamiento */
}

/* Estilo del pulgar de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color de fondo del pulgar */
  border-radius: 15px; /* Borde redondeado del pulgar */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}